import React, { lazy } from "react";
import executor from "common/utils/executor";
import AuthGuard from "guards/auth.guard";
import OrganizationGuard from "guards/organization.guard";

const Auth = lazy(() => import("./pages/Auth"));
const Organization = lazy(() => import("./pages/Organization"));
const SetupOrg = lazy(() => import("./pages/SetupOrg"));
const ChooseOrg = lazy(() => import("./pages/ChooseOrg"));
const Profile = lazy(() => import("./pages/Profile"));
const EmailUpdateConfirmation = lazy(() => import("./pages/Profile/ProfileSettings/EmailUpdated"));
// const Redirection = lazy(() => import("./pages/Redirection"));
const EchoPlayGround = lazy(() => import("./pages/Patients/EchoPlayGround/index"));
const ECGPlayGround = lazy(() => import("./pages/Patients/ECGPlayGround/index"));
const UserInfo = lazy(() => import("./pages/UserInfo"));

const routes = [
    {
        path: "/auth/*",
        component: Auth,
        guard: () => {
            return executor([AuthGuard]);
        },
    },
    {
        path: "/userinfo",
        component: UserInfo,
        guard: () => {
            return executor([AuthGuard]);
        },
    },
    {
        path: "/choose-org",
        component: ChooseOrg,
        guard: () => {
            return executor([AuthGuard, OrganizationGuard]);
        },
    },
    {
        path: "/setup-org",
        component: SetupOrg,
        guard: () => {
            return executor([AuthGuard, OrganizationGuard]);
        },
    },
    // {
    //     path: "org/:orgId/hosp/:hospitalId/profile/*",
    //     component: Profile,
    //     guard: () => {
    //         return executor([AuthGuard]);
    //     },
    // },
    {
        path: "/org/:orgId/hosp/:hospitalId/*",
        component: Organization,
        guard: () => {
            return executor([AuthGuard, OrganizationGuard]);
        },
    },

    {
        path: "/org/:orgId/hosp/:hospitalId/workspace/:patientId/studyId/:studyId",
        component: EchoPlayGround,
        guard: () => {
            return executor([AuthGuard, OrganizationGuard]);
        },
    },

    {
        path: "/org/:orgId/hosp/:hospitalId/workspace/ecg/:patientId/studyId/:studyId",
        component: ECGPlayGround,
        guard: () => {
            return executor([AuthGuard, OrganizationGuard]);
        },
    },

    {
        path: "/email-update",
        component: EmailUpdateConfirmation,
        guard: () => {
            return executor([]);
        },
    },
    // {
    //     path: "/redirect",
    //     component: Redirection,
    //     guard: () => {
    //         return executor([]);
    //     },
    // },
    {
        path: "*",
        component: Organization,
        guard: () => {
            return executor([AuthGuard, OrganizationGuard]);
        },
    },
];

export default routes;

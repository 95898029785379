import { OrgAction } from "../actions/orgActions";

const initialState = {
    isOrgRegistered: false,
    organization: null,
    organizations: [],
    member: null,
    selectedHospital: null,
    organizationLocation: null,
};

const orgReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case OrgAction.CreateOrganizationSuccess:
            return {
                ...state,
                ...payload,
            };
        case OrgAction.CreateOrganizationFailed:
            return {
                ...state,
                ...payload,
            };
        case OrgAction.GetOrganizationDetails:
            return {
                ...state,
                ...payload,
            };
        case OrgAction.GetOrganizationPreference:
            return {
                ...state,
                ...payload,
            };
        case OrgAction.Logout:
            return {
                ...state,
                ...payload,
            };
        case OrgAction.GetOrganizationsForUser:
            return {
                ...state,
                ...payload,
            };
        case OrgAction.SetCurrentOrganization:
            return {
                ...state,
                ...payload,
            };
        case OrgAction.updateCurrentOrganization:
            return {
                ...state,
                ...payload,
            };

        case OrgAction.UpdateSelectedHospital:
            return {
                ...state,
                selectedHospital: action.payload.selectedHospital,
            };

        case OrgAction.SetOrganizationLocation:
            return {
                ...state,
                organizationLocation: action.payload.organizationLocation,
            };
        default:
            return state;
    }
};

export default orgReducer;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import environment from "../../environment";
import EventEmitter from "../events";
import { AuthorisedUser, SegmentScriptInitiated } from "../events/events";
import { initiateScript } from "../redux/actions/segmentActions";

const useScriptsHooks = () => {
    const { isUserLoggedIn, user } = useSelector((state) => state.authenticationDetails);
    const [isFcScriptInitiated, setFcScriptInitiated] = useState(false);
    const isSegmentScriptInitiated = useSelector(
        (state) => state.segmentDetails.isSegmentScriptInitiated,
    );
    const dispatch = useDispatch();
    useEffect(() => {
        window["dataLayer"] = window["dataLayer"] || [];
        if (environment.VALUE !== "development" && environment.GTM_CONTAINER_ID) {
            window["dataLayer"].push({
                "gtm.start": new Date().getTime(),
                event: "gtm.js",
            });
            const f = document.getElementsByTagName("script")[0],
                j = document.createElement("script"),
                dl = "&l=dataLayer";
            j.async = true;
            j.defer = true;
            const i = environment.GTM_CONTAINER_ID;
            j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
            f?.parentNode?.insertBefore(j, f);

            // Second GTM (noscript) iframe
            const noscriptTag = document.createElement("noscript");
            const iframeTag = document.createElement("iframe");
            iframeTag.src = `https://www.googletagmanager.com/ns.html?id=${i}`;
            iframeTag.height = "0";
            iframeTag.width = "0";
            iframeTag.style.display = "none";
            iframeTag.style.visibility = "hidden";
            noscriptTag.appendChild(iframeTag);
            document.body.appendChild(noscriptTag);

            // Usersnap script setup
            window.onUsersnapLoad = function (api) {
                api.init();
            };
            const usersnap_key = "84de98e8-9dc7-4c84-8a46-a83318bc233c";
            // const usersnap_key = environment.USERSNAP_API_KEY;
            const usersnapScript = document.createElement("script");
            usersnapScript.defer = true;
            usersnapScript.src = `https://widget.usersnap.com/global/load/${usersnap_key}?onload=onUsersnapLoad`;
            document.getElementsByTagName("head")[0].appendChild(usersnapScript);
        }
    }, []);

    useEffect(() => {
        if (isUserLoggedIn && isFcScriptInitiated) {
            EventEmitter.dispatch(AuthorisedUser);
        }
    }, [isUserLoggedIn, isFcScriptInitiated]);

    EventEmitter.on(SegmentScriptInitiated, function () {
        initiateScript(
            {
                isSegmentScriptInitiated: true,
            },
            dispatch,
        );
    });

    return "";
};

export default useScriptsHooks;
